import React from 'react';

import { URL } from 'src/resources/constants/url';
import { DEFAULT_FLEX_RATE } from 'src/resources/constants/app';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { USER } from 'src/resources/constants/user';
import { HtmlLang, LabelLang } from 'src/lang/components';

import jeangalea from './img/jeangalea.png';
import financialprofessional from './img/financialprofessional.png';
import freeBitcon from './img/free_bitcoin.png';
import theteam from './img/account.svg';
import testimonials from './img/testimonials.svg';
import membership from './img/membership.svg';
import shareAndearn from './img/share&earn.svg';
import affiliate from './img/affiliate.svg';
import achievement from './img/achievement.svg';
import badges from './img/badges.svg';
import blog from './img/blog.svg';
import supportCenter from './img/supportCenter.svg';
import pricing from './img/pricing.svg';
import security from './img/security&risk.svg';
import statistic from './img/statistics.svg';
import shortAdvanced from './img/shortAdvanced.svg';
import shortSimple from './img/shortSimple.svg';
import nft from './img/nft.png';
import discord from './img/discord.svg';

export const modalMenuOn = () => {
  const modal = document.getElementById('OVER');
  if (!modal) {
    const gab = document.createElement('div');
    gab.setAttribute('id', 'OVER');
    gab.setAttribute('onClick', 'modalOff()');
    // gab.innerHTML = '<div class="overlay"></div>';
    document.body.appendChild(gab);
  }
};

export const modalMenuOff = () => {
  const modal = document.getElementById('OVER');
  if (modal) document.body.removeChild(modal);
};

const sendGaEventOnClickMenu = (category, action) => {
  const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  ga(category, action, email);
};

export const DOWNLOAD_APP_FROM_QR_CODE = 'download_app';

const ACTIVITY_MENUS = category => [
  // {
  //   title: <div className="badges">MCT Token</div>,
  //   onClick: () => sendGaEventOnClickMenu(category, events.action.menuActivityMCTToken),
  //   url: URL.CONSTANT_TOKEN,
  //   img: achievement
  // },
  // {
  //   title: <div className="badges">MCT Airdrop</div>,
  //   onClick: () => sendGaEventOnClickMenu(category, events.action.menuActivityMCTAirdrop),
  //   url: URL.AIRDROP_WHEEL,
  //   img: achievement
  // },
  {
    title: <LabelLang id="user.prizeWheel" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityPrizeWheel);
    },
    url: URL.MCT_PRIZE_WHEEL,
    img: membership
  },
  {
    title: <><LabelLang id="footer.badges" /><img width={15} height={15} alt="" src={badges} /></>,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityBadges);
    },
    url: URL.BADGES,
    img: achievement
  },
  {
    title: <LabelLang id="user.memberShip" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityMembership);
    },
    url: URL.MEMBERSHIP,
    img: membership
  },
  {
    title: <LabelLang id="footer.referral" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityReferral);
    },
    url: URL.REFERRAL,
    img: shareAndearn,
    id: 'referral_menu_about'
  },
  {
    title: <LabelLang id="footer.affiliate" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityAffiliate);
    },
    url: URL.AFFILIATES,
    img: affiliate
  },
  {
    title: <LabelLang id="footer.personalBudget" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuActivityPersonalBudget);
    },
    url: URL.PERSONAL_BUDGET_DASHBOARD,
    unAuthUrl: URL.PERSONAL_BUDGET_LANDING,
    img: affiliate
  },
];

const EXTRA_ABOUT_MENUS = category => [
  // {
  //   title: <LabelLang id="headerBar.ourStory" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     sendGaEventOnClickMenu(category, events.action.menuAboutOurStory);
  //   },
  //   url: URL.ABOUT_US,
  //   img: theteam
  // },
  {
    title: <LabelLang id="headerBar.testimonial" />,
    onClick: () => {
      modalMenuOff();
      sendGaEventOnClickMenu(category, events.action.menuAboutBlog);
    },
    url: URL.TESTIMONIALS,
    img: testimonials
  },
  // {
  //   title: <LabelLang id="footer.blog" />,
  //   onClick: (e) => {
  //     e.preventDefault();
  //     modalMenuOff();
  //     sendGaEventOnClickMenu(category, events.action.menuAboutBlog);
  //     window.open(URL.BLOG_WORDPRESS, '_blank');
  //   },
  //   url: URL.BLOG_WORDPRESS,
  //   img: blog,
  // },
];

export const NEW_INVEST_EXTRA_MENUS = ({cryptoLendRate, flexCollateralsList, cryptoLendRateStable, numCollateral, maxStakingRate}) => ({
  title: <LabelLang id="headerBar.extraMenu.invest.title" />,
  desc: <HtmlLang id="headerBar.extraMenu.invest.desc" />,
  type: 'invest',
  menu: [
    {
      title: <LabelLang id="headerBar.extraMenu.depositFiat.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.depositFiat.desc" values={{ interest: DEFAULT_FLEX_RATE.toString() }} />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestDepositFiat);
      },
      url: URL.DEPOSIT_FIAT,
    },
    {
      title: <LabelLang id="headerBar.extraMenu.investCustom.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.investCustom.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestCryptoBacked);
      },
      url: URL.INVEST
    },
    // {
    //   title: <LabelLang id="headerBar.extraMenu.depositCrypto.title" />,
    //   desc: <HtmlLang id="headerBar.extraMenu.depositCrypto.desc" values={{ numCollateral: numCollateral }} />,
    //   onClick: () => sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestDepositCrypto),
    //   url: URL.DEPOSIT_CRYPTO,
    // },
    {
      title: <LabelLang id="headerBar.extraMenu.investFlexCrypto.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.investFlexCrypto.desc" values={{ interest: cryptoLendRateStable, cryptos: flexCollateralsList?.map(c => c.symbol).join(', ') }} />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestCryptoLend);
      },
      url: URL.FLEX_CRYPTO,
    },
    {
      title: <LabelLang id="headerBar.extraMenu.lottery.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.lottery.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestLottery);
      },
      url: URL.LENDING_LOTTERY,
    },
    // {
    //   title: <LabelLang id="headerBar.extraMenu.secondaryMarket.title" />,
    //   desc: <HtmlLang id="headerBar.extraMenu.secondaryMarket.desc" />,
    //   onClick: () => sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestSecondaryMarket),
    //   url: URL.SECONDARY_MARKET,
    // },
    {
      title: <LabelLang id="headerBar.extraMenu.mctStaking.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.mctStaking.desc" values={{max_staking_rate: maxStakingRate}}/>,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestStakingMCT);
      },
      url: URL.MCT_STAKING,
    },
    {
      title: <LabelLang id="headerBar.extraMenu.mctBuying.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.mctBuying.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestBuyMCT);
      },
      url: URL.BUY_MCT,
    },
    // {
    //   title: <LabelLang id="headerBar.extraMenu.investLO.title" />,
    //   desc: <HtmlLang id="headerBar.extraMenu.investLO.desc" />,
    //   onClick: () => {
    //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
    //     ga(events.category.menuInvest, events.action.menuLoanOriginator, email);
    //   },
    //   url: `${URL.LOAN_ORIGINATORS}${URL.LO_MANUAL_INVEST}`
    // },
  ],
  activity: ACTIVITY_MENUS(events.category.menuInvest).concat([
    {
      title: <LabelLang id="headerBar.extraMenu.depositCrypto.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.depositCrypto.desc" values={{ numCollateral: numCollateral }} />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestDepositCrypto);
      },
      url: URL.DEPOSIT_CRYPTO,
    },
    {
      title: <LabelLang id="headerBar.extraMenu.secondaryMarket.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.secondaryMarket.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuInvest, events.action.menuInvestSecondaryMarket);
      },
      url: URL.SECONDARY_MARKET,
    },
  ]),
  about: EXTRA_ABOUT_MENUS(events.category.menuInvest),
});

export const NEW_BORROW_EXTRA_MENUS = {
  title: <LabelLang id="headerBar.extraMenu.borrow.title" />,
  desc: <HtmlLang id="headerBar.extraMenu.borrow.desc" />,
  type: 'borrow',
  menu: [
    {
      title: <LabelLang id="headerBar.extraMenu.borrowFiat.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.borrowFiat.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuBorrowCryptoBacked);
      },
      url: URL.CRYPTO_LOAN,
    },
    {
      title: <><LabelLang id="headerBar.extraMenu.cryptoSwap.title" /></>,
      desc: <HtmlLang id="headerBar.extraMenu.cryptoSwap.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuCryptoSwap);
      },
      url: URL.CRYPTO_SWAP,
    },
    {
      title: <><LabelLang id="headerBar.extraMenu.poFinancingLoans.title" /></>,
      desc: <HtmlLang id="headerBar.extraMenu.poFinancingLoans.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuBorrowPoFinancingLoans);
      },
      url: URL.PO_FINANCING_LOANS,
    },
    {
      title: <LabelLang id="headerBar.extraMenu.cryptoFuture.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.cryptoFuture.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuCryptoFuture);
      },
      url: URL.CRYPTO_FUTURE,
    },
    // {
    //   title: <><LabelLang id="headerBar.extraMenu.cryptoShort.title" /><img alt="" src={shortAdvanced} /> </>,
    //   desc: <HtmlLang id="headerBar.extraMenu.cryptoShort.desc" />,
    //   onClick: () => {
    //     modalMenuOff();
    //     sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuCryptoShort);
    //   },
    //   url: URL.CRYPTO_SHORT,
    // },
    {
      title: <><LabelLang id="headerBar.extraMenu.institutionalLoans.title" /></>,
      desc: <HtmlLang id="headerBar.extraMenu.institutionalLoans.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuBorrowInstitutionalLoans);
      },
      url: URL.INSTITUTIONAL_LOANS,
    },
    {
      title: <><LabelLang id="headerBar.extraMenu.shortSimple.title" /><img alt="" src={shortSimple} /></>,
      desc: <HtmlLang id="headerBar.extraMenu.shortSimple.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuShortBasic);
      },
      url: URL.SHORT_SIMPLE,
    },
  ],
  activity: ACTIVITY_MENUS(events.category.menuBorrow).concat([
    {
      title: <><LabelLang id="headerBar.extraMenu.cryptoShort.title" /><img alt="" src={shortAdvanced} style={{marginTop: '-20px', marginLeft: '5px'}}/> </>,
      desc: <HtmlLang id="headerBar.extraMenu.cryptoShort.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuBorrow, events.action.menuCryptoShort);
      },
      url: URL.CRYPTO_SHORT,
    }]),
  about: EXTRA_ABOUT_MENUS(events.category.menuBorrow),
};

export const NEW_SPENDING_EXTRA_MENUS = {
  title: <LabelLang id="headerBar.extraMenu.spending.title" />,
  desc: <HtmlLang id="headerBar.extraMenu.spending.desc" />,
  type: 'spending',
  menu: [
    // {
    //   title: <LabelLang id="headerBar.extraMenu.giftCard.title" />,
    //   desc: <HtmlLang id="headerBar.extraMenu.giftCard.desc" />,
    //   onClick: () => sendGaEventOnClickMenu(events.category.menuSpending, events.action.menuSpendingGiftCard),
    //   url: URL.GIFT_CARDS,
    // },
    {
      title: <LabelLang id="headerBar.extraMenu.debitCard.title" />,
      desc: <HtmlLang id="headerBar.extraMenu.debitCard.desc" />,
      onClick: () => {
        modalMenuOff();
        sendGaEventOnClickMenu(events.category.menuSpending, events.action.menuSpendingDebitCard);
      },
      url: URL.DEBIT_CARD_LANDING,
      // unAuthUrl: URL.DEBIT_CARD_LANDING,
      // onlyAllowUS: true,
    },
  ],
  activity: ACTIVITY_MENUS(events.category.menuSpending),
  about: EXTRA_ABOUT_MENUS(events.category.menuSpending),
};

export const COMMUNITY_MENUS = [
  // {
  //   title: <LabelLang id="headerBar.liveChat" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  //     ga(events.category.menuCommunity, events.action.menuCommunityLiveChat, email);
  //   },
  //   url: URL.LIVE_CHAT,
  //   img: supportCenter
  // },
  {
    title: 'Discord',
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuCommunity, events.action.menuCommunityDiscord, email);
    },
    url: URL.DISCORD,
    img: discord
  }
];

export const MEMBERSHIP_NFT_MENUS = [
  {
    title: 'MCT Membership',
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuMembership, events.action.menuMembershipMCTMembership, email);
    },
    url: URL.MEMBERSHIP,
    img: membership
  },
  {
    title: 'NFT Collection',
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuMembership, events.action.menuMembershipMCTMascot, email);
    },
    url: URL.MCT_MASCOT,
    img: nft
  }
];

export const ABOUT_MENUS = [
  // {
  //   title: <LabelLang id="headerBar.ourStory" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  //     ga(events.category.menuAbout, events.action.menuAboutOurStory, email);
  //   },
  //   url: URL.ABOUT_US,
  //   img: theteam
  // },
  {
    title: <LabelLang id="headerBar.testimonial" />,
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuAbout, events.action.menuAboutWhyPepoleLoveUs, email);
    },
    url: URL.TESTIMONIALS,
    img: testimonials
  },
  {
    title: <div className="badges"><LabelLang id="footer.badges" /></div>,
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuAbout, events.action.menuAboutBadges, email);
    },
    url: URL.BADGES,
    img: achievement
  },
  // {
  //   title: <LabelLang id="footer.statistics" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  //     ga(events.category.menuAbout, events.action.menuAboutSupportCenter, email);
  //   },
  //   url: URL.STATISTICS,
  //   img: statistic,
  // },
  // {
  //   title: <LabelLang id="footer.blog" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  //     ga(events.category.menuAbout, events.action.menuAboutBlog, email);
  //     window.open(URL.BLOG_WORDPRESS, '_blank');
  //   },
  //   img: blog
  // },
  {
    title: <LabelLang id="footer.prices" />,
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuAbout, events.action.menuAboutPricing, email);
    },
    url: URL.FEE_AND_SERVICES_TIMES,
    img: pricing
  },
  {
    title: <LabelLang id="footer.securityAndRisk" />,
    onClick: () => {
      modalMenuOff();
      const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
      ga(events.category.menuAbout, events.action.menuAboutSecurityAndRisk, email);
    },
    url: URL.LO_SECURITY_RISK,
    img: security
  },
  // {
  //   title: <LabelLang id="footer.supportCenter" />,
  //   onClick: () => {
  //     modalMenuOff();
  //     const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  //     ga(events.category.menuAbout, events.action.menuAboutSupportCenter, email);
  //   },
  //   url: URL.SUPPORT_CENTER,
  //   img: supportCenter
  // },
];

export const AFFILIATES = [
  {
    path: URL.AFFILIATE_JEANGALEA,
    logo_url: jeangalea,
    website_url: 'http://jeangalea.com/'
  },
  {
    path: URL.AFFILIATE_FINANCIAL_PROFESSIONAL,
    logo_url: financialprofessional,
    website_url: 'https://financialprofessional.com/'
  },
  {
    path: URL.AFFILIATE_FREE_BITCON,
    logo_url: freeBitcon,
    website_url: 'https://freebitcoin.io/'
  },
];
